<template>
  <div>
    <h3 class="pb-2">
      User Role Management
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.userRoleForm"
    />
    <div v-if="!loading">
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">User Roles</div>
        </div>
        <div class="card-body">
          <ValidationObserver ref="userRoleForm">
            <form @submit.prevent="onSubmit">
              <div class="d-flex flex-wrap">
                <div
                  v-for="userRole in userRoles"
                  :key="userRole.id"
                  class="mr-3"
                >
                  <div
                    class="mb-2"
                    style="font-size: large; font-weight: bold;"
                  >
                    {{ userRole.name }}
                  </div>
                  <div
                    v-for="permissionArea in permissionAreas"
                    :key="permissionArea.id"
                  >
                    <div v-if="filterPermissions(permissionArea.id).length > 0">
                      <div style="font-weight: bold;">
                        {{ permissionArea.name }}
                      </div>
                      <b-form-checkbox-group
                        v-model="userRole.userRolePermissionIds"
                        :options="filterPermissions(permissionArea.id)"
                        switches
                        stacked
                        size="md"
                        :disabled="viewOnly"
                        class="mb-2"
                      ></b-form-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-lg btn-primary mt-4 mr-2"
                v-show="!viewOnly"
              >
                Save
              </button>
              <button
                class="btn btn-lg btn-primary mt-4 mr-2"
                @click="viewOnly = false"
                type="button"
                v-show="viewOnly"
              >
                Modify
              </button>
              <button
                class="btn btn-cancel btn-lg mt-4 mr-2"
                @click="cancel"
                type="button"
              >
                Cancel
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import AdminUserService from "@/services/admin/AdminUserService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";

export default {
  name: "AdminUserRoleManagement",
  title: "Admin - User Role Management",
  components: {
    ValidationObserver,
    FormErrorAlert
  },
  data() {
    return {
      loading: false,
      errors: [],
      userRoles: [],
      viewOnly: true,
      permissions: [],
      permissionAreas: []
    };
  },
  methods: {
    filterPermissions(permissionAreaId) {
      return this.permissions.filter(
        x => x.permissionAreaId === permissionAreaId
      );
    },
    async getUserRoles() {
      const service = new AdminUserService(this.tenantId);
      return await service.getUserRoles();
    },
    async getPermissions() {
      const service = new AdminLookupService(this.tenantId);
      return await service.getPermissions();
    },
    async getPermissionAreas() {
      const service = new AdminLookupService(this.tenantId);
      return await service.getPermissionAreas();
    },
    async onSubmit() {
      this.$refs.userRoleForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.userRoleForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.userRoleForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.loading = true;
            const service = new AdminUserService(this.tenantId);
            const response = await service.updateUserRoles(this.userRoles);
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "User Roles successfully modified.",
                layer: "admin"
              });
              await this.initialize();
              this.cancel();
              this.loading = false;
            } else {
              this.loading = false;
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    },
    cancel() {
      this.errors = [];
      this.viewOnly = true;
      this.initialize();
    },
    async initialize() {
      this.loading = true;
      const userRoles = this.getUserRoles();
      const permissions = this.getPermissions();
      const permissionAreas = this.getPermissionAreas();
      await Promise.allSettled([userRoles, permissions, permissionAreas])
        .then(responses => {
          this.userRoles = responses[0].value
            .filter(y => y.id !== 1) // NIC admin doesn't show
            .map(x => {
              return {
                id: x.id,
                name: x.name,
                userRolePermissionIds: x.permissions.map(z => z.id)
              };
            });
          this.permissions = responses[1].value.map(x => {
            return {
              value: x.id,
              text: x.name,
              disabled: x.id === 1 || x.id === 17,
              permissionAreaId: x.permissionAreaId
            };
          });
          this.permissionAreas = responses[2].value;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style>
.actionsColumn {
  width: 300px !important;
}
</style>
